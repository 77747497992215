import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';
import { Script } from 'gatsby';
import React, { useCallback, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {
  autocompleteOptions,
  convertAutocompleteDataToHubspotFields,
} from '../../components/Forms/utils/autocomplete';
import PartyPopperIcon from '../../images/party-icon.svg';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import '../../styles/overrides/modal-overrides.scss';
import Image from '../../utils/OptimizedImage';
import { ModalBody } from './ShopPerformanceStyles';
import { locationCounts, regions, shopTypes } from './helpers';

const SubmitModal = ({
  formData,
  loading = false,
  onSubmit,
  raffle = false,
  setFormData,
  ...props
}) => {
  const emailRegEx = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
  const nonDigitRegEx = /[^0-9]/g;
  const [hasError, setHasError] = useState(false);
  const placesAutocomplete = useRef(null);

  const initPlacesAutocomplete = () => {
    if (window.google?.maps?.places?.Autocomplete) {
      const triggerInput = document.querySelector(
        '.modal-content [name="company"]'
      );

      placesAutocomplete.current = new window.google.maps.places.Autocomplete(
        triggerInput,
        autocompleteOptions
      );

      // Set hidden field values when a place is selected
      placesAutocomplete.current.addListener('place_changed', () => {
        const selectedPlace = placesAutocomplete.current.getPlace();
        const convertedFields = convertAutocompleteDataToHubspotFields(
          selectedPlace
        );
        const visibleFields = {};

        document.querySelectorAll('.modal-content [name]').forEach(input => {
          visibleFields[input.name] = input.value;
        });

        setFormData({
          ...visibleFields,
          ...convertedFields,
        });
      });

      // Clear hidden field values when the company name input value changes
      triggerInput.addEventListener('change', () => {
        const visibleFields = {};

        document.querySelectorAll('.modal-content [name]').forEach(input => {
          visibleFields[input.name] = input.value;
        });

        setFormData(visibleFields);
      });
    }
  };

  const handleSubmit = useCallback(() => {
    formData.firstname &&
    formData.lastname &&
    formData.email &&
    emailRegEx.test(formData.email) &&
    formData.phone &&
    formData.phone.replace(nonDigitRegEx, '').length > 4 &&
    // Raffle version requires region and number of locations
    // and regular version requires company and shop type
    (raffle ? formData.region !== 'none' : formData.company) &&
    (raffle
      ? formData.number_of_locations !== 'none'
      : formData.shop_type !== 'none')
      ? onSubmit()
      : setHasError(true);
  }, [
    emailRegEx,
    formData.company,
    formData.email,
    formData.firstname,
    formData.lastname,
    formData.number_of_locations,
    formData.phone,
    formData.region,
    formData.shop_type,
    nonDigitRegEx,
    onSubmit,
    raffle,
  ]);

  const handleChange = useCallback(
    ({ target }) =>
      setFormData({
        ...formData,
        [target.name]: target.value,
      }),
    [formData, setFormData]
  );

  return (
    <Modal centered {...props}>
      <ModalBody>
        <Image src={PartyPopperIcon} alt="success" width="47px" height="47px" />
        <h4 className="heading">
          {raffle ? 'Survey Completed!' : 'Assessment Completed!'}
        </h4>
        <p className="subhead">
          {raffle
            ? 'Enter your information to complete your raffle entry.'
            : 'Where would you like us to send a copy of the report and recommendations?'}
        </p>
        <div className="form-container">
          <div className="see-results-form">
            <form>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="firstname" className="control-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      className={clsx(
                        'form-control',
                        hasError && !formData.firstname && 'is-invalid'
                      )}
                      disabled={loading}
                      id="firstname"
                      name="firstname"
                      onChange={handleChange}
                      placeholder="Enter first name"
                      required
                      type="text"
                      value={formData.firstname}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="lastname" className="control-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className={clsx(
                        'form-control',
                        hasError && !formData.lastname && 'is-invalid'
                      )}
                      disabled={loading}
                      id="lastname"
                      name="lastname"
                      onChange={handleChange}
                      placeholder="Enter last name"
                      required
                      type="text"
                      value={formData.lastname}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="email" className="control-label">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      className={clsx(
                        'form-control',
                        hasError &&
                          (!formData.email ||
                            !emailRegEx.test(formData.email)) &&
                          'is-invalid'
                      )}
                      disabled={loading}
                      id="email"
                      name="email"
                      onChange={handleChange}
                      placeholder="Enter email address"
                      required
                      type="email"
                      value={formData.email}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="phone" className="control-label">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      className={clsx(
                        'form-control',
                        hasError &&
                          (!formData.phone ||
                            formData.phone.replace(nonDigitRegEx, '').length <
                              5) &&
                          'is-invalid'
                      )}
                      disabled={loading}
                      id="phone"
                      name="phone"
                      onChange={handleChange}
                      placeholder="Enter phone number"
                      required
                      type="tel"
                      value={formData.phone}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {raffle ? (
                <>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="region" className="control-label">
                      In which region is your shop located?
                    </Form.Label>
                    <Select
                      className={clsx(
                        'form-control select',
                        hasError && formData.region === 'none' && 'is-invalid'
                      )}
                      disabled={loading}
                      disableUnderline
                      id="region"
                      name="region"
                      onChange={handleChange}
                      value={formData.region}
                    >
                      <MenuItem value="none" disabled>
                        Select region
                      </MenuItem>
                      {regions.map((region, index) => (
                        <MenuItem value={region} key={`item-${index + 1}`}>
                          {region}
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label
                      htmlFor="number_of_locations"
                      className="control-label"
                    >
                      Number of locations
                    </Form.Label>
                    <Select
                      className={clsx(
                        'form-control select',
                        hasError &&
                          formData.number_of_locations === 'none' &&
                          'is-invalid'
                      )}
                      disabled={loading}
                      disableUnderline
                      id="number_of_locations"
                      name="number_of_locations"
                      onChange={handleChange}
                      value={formData.number_of_locations}
                    >
                      <MenuItem value="none" disabled>
                        Select number of locations
                      </MenuItem>
                      {locationCounts.map((count, index) => (
                        <MenuItem value={count} key={`item-${index + 1}`}>
                          {count}
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="company" className="control-label">
                      Shop Name
                    </Form.Label>
                    <Form.Control
                      className={clsx(
                        'form-control',
                        hasError && !formData.company && 'is-invalid'
                      )}
                      disabled={loading}
                      id="company"
                      name="company"
                      onChange={handleChange}
                      placeholder="Enter name of shop"
                      required
                      type="text"
                      value={formData.company}
                    />
                  </Form.Group>
                  <Form.Group className="form-group required mb-3">
                    <Form.Label htmlFor="shop_type" className="control-label">
                      Shop Type
                    </Form.Label>
                    <Select
                      className={clsx(
                        'form-control select capitalize',
                        hasError &&
                          formData.shop_type === 'none' &&
                          'is-invalid'
                      )}
                      disabled={loading}
                      disableUnderline
                      id="shop_type"
                      name="shop_type"
                      onChange={handleChange}
                      value={formData.shop_type}
                    >
                      <MenuItem value="none" disabled>
                        Select shop type
                      </MenuItem>
                      {shopTypes.map((type, index) => (
                        <MenuItem
                          value={type}
                          key={`item-${index + 1}`}
                          className="capitalize"
                        >
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Form.Group>
                </>
              )}
              <CallToAction
                className="w-100 mt-3 mb-2"
                customHandleClick={() => handleSubmit()}
                disabled={loading}
                size="xl"
                value={raffle ? 'Enter to Win' : 'See My Results'}
                variant="primary"
              />
            </form>
          </div>
        </div>
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`}
          onLoad={initPlacesAutocomplete}
        />
      </ModalBody>
    </Modal>
  );
};

export default SubmitModal;
